/* You can add global styles to this file, and also import other style files */
html,body
	margin: 0
	height: 100vh
	overflow: hidden

.pp-home
	height: 100%
	display: grid
	justify-content: center
	align-content: center

	&__logo
		height: 90vh

.playground
	padding: 20px
	display: grid
	grid-auto-rows: auto
	grid-gap: 10px

.pp-generated-code
	position: relative

	.pp-generated-code__copy-icon
		position: absolute
		z-index: 1
		right: 15px
		top: 5px
		width: 40px
		height: 40px
		filter: invert(1)
		cursor: pointer
		transition: all .2s ease-in-out
		&:hover
			filter: invert(0.8)

.pp-section
	display: grid
	grid-auto-rows: auto
	grid-gap: 10px
	height: 100%
	align-content: baseline

	&__header
		display: grid
		grid-template-columns: min-content auto
		height: fit-content

		&__title
			margin: 0 !important
			width: min-content

		&__arrow
			cursor: pointer
			align-self: center

		&__hr
			background-color: white
			width: 100%
			grid-column: 1 / -1

	&__main
		display: grid
		grid-gap: 10px
		container-type: inline-size

		&--one-col
			grid-template-columns: 1fr
		&--two-col
			grid-template-columns: repeat(2, 1fr)
		&--three-col
			grid-template-columns: repeat(3, 1fr)
		&--six-col
			grid-template-columns: repeat(6, 1fr)

		&--untoggled
			height: 0px
			overflow: hidden

.pp-section__main--resizable
	resize: horizontal
	overflow: auto

.mat-drawer-inner-container
	overflow: initial !important

// ============= Mobile styles =============
@media (max-width: 768px)
	.pp-section__main
		&--one-col
			grid-template-columns: 1fr
		&--two-col
			grid-template-columns: 1fr
		&--three-col
			grid-template-columns: 1fr
		&--six-col
			grid-template-columns: 1fr